module.exports = {
  // App Info
  appstore_link         : "https://appstore.link",
  windows_link          : "https://windowdownload.s3",
  mac_link              : "https://macdownload.s3",                                   // Enter App Store URL.
  //playstore_link        : "https://imedadel.me",                                  // Enter Google Play Store URL.
  //google_analytics_ID   : "UA-47311644-5",                                        // Enter Google Analytics ID or ""
  //presskit_download_link: "https://imedadel.me",                                  // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file). 
  video_or_screenshot   : "screenshot",                                           // "screenshot" or "video"
  app_url               : "https://appstore.link",                            // Domain of your website without path_prefix.
  path_prefix           : "/",                                                    // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name              : "Dinner Time",
  app_price             : "99p",
  app_description       : "Are you fed up with you children or partner ignoring you at dinner time?",
  app_keywords          : ["dinner", "alarm", "app", "landing page"],

  // Personal Info
  your_name              : "Fuzzy Badger & Video Walrus",
  your_link              : "https://fuzzy-badger.co.uk",
  your_city              : "UK",
  email_address          : "hello@fuzzybadger.co.uk",
  linkedin_username      : null,
  facebook_username      : null,
  instagram_username     : null,
  twitter_username       : null,
  github_username        : "gazzer82",
  youtube_username       : null,

  // Features List
  features               : [
    {
      title: "Send Alerts",
      description:
        "Now you can send an alert easily from your iPhone when it's Dinner Time! It's loud, annoying and impossible to ignore!",
      fontawesome_icon_name: "bell",
    },
    {
      title: "Get a Reply",
      description:
        "Keep the app open and get a reply when people are on their way.",
      fontawesome_icon_name: "comment",
    },
    {
      title: "Download",
      description:
        "Just install the free companion app on as many <a href=https://aws-website-videowalrus-h569u.s3.amazonaws.com/PiXL%20Grid%20Installers/DinnerTime/DinnerTime1.pkg>MacOS</a> or <a href=https://aws-website-videowalrus-h569u.s3.amazonaws.com/PiXL%20Grid%20Installers/DinnerTime/DinnerTime%20Installer%2064Bit.zip>Windows</a> computers as you like for free",
      fontawesome_icon_name: "store",
    }
  ],
  header_background             : "rgba(0, 0, 0, 0.1)",
  topbar_title_color            : "#ffffff",
  cover_overlay_color_rgba      : "rgba(54, 59, 61, 0.8)",
  device_color                  : "black",                          // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color         : "ffffff",
  primary_text_color            : "#000",
  content_width                 : "1170px",
  font                          : `"Helvetica Neue", sans-serif`,
  link_color                    : "#1d63ea",
  app_title_color               : "#ffffff",
  app_price_color               : "#ffffff",
  app_description_color         : "#ffffff",
  feature_title_color           : "#000000",
  feature_text_color            : "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color : "#666666",
  social_icons_background_color : "#e6e6e6",
  footer_text_color             : "#666666",
}
